import casesGroup from "../../assets/cases-group.png";

function CasesMain() {
    return ( 
        <>
            <section className="cases-main">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <h1>
                                Some of our <br />projects
                            </h1>
                            <p>
                            We develop and promote modern web services that deliver lasting results for your business
                            </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="image-group">
                                <img src={casesGroup} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default CasesMain;