function AboutUsMain() {
    return ( 
        <>
            <section className="about-us-main">
                <div className="container">
                    <div className="heading-section">
                        <div className="heading">
                            <h1>Driven by</h1>
                            <div className="line"></div>
                        </div>
                        <div className="heading">
                            <div className="line"></div>
                            <h1>digital</h1>                                
                        </div>
                        <div className="heading">
                            <h1>Excellence</h1>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default AboutUsMain;